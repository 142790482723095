<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="clearfix">
    <div class="vx-row">

      <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
        <p > Nom <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="last_name"
          v-model="last_name"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('last_name') }}</span>

        <p > Prénoms <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="first_name"
          v-model="first_name"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('first_name') }}</span>

        <p > Date de naissance <b style="color: #ff6141" >*</b> </p>
        <datepicker
          label-placeholder="Select Date"
          v-model="birthDate"
          v-validate="'required'"
          name="birthDate"
          class="w-full"
          ></datepicker>
        <span class="text-danger text-sm">{{ errors.first('birthDate') }}</span>

        <p > Sexe <b style="color: #ff6141" >*</b> </p>
        <v-select
          v-validate="'required'"
          name="gender"
          :reduce="rep => rep.key"
          class="w-full" label="name"
          v-model="gender"
          :options="genders">
        </v-select>
        <span class="text-danger text-sm">{{ errors.first('gender') }}</span>

        <p > Nom d'utilisateur <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="displayName"
          v-model="displayName"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('displayName') }}</span>

        <p > Email <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required|email'"
          data-vv-validate-on="blur"
          name="email"
          type="email"
          v-model="email"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
      </div>

      <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">

        <p  class="pt-4">Téléphone <b style="color: #ff6141" >*</b> </p>
        <vue-tel-input
          v-on:country-changed="countryChanged"
          inputOptions.placeholder="Telephone"
          :selectedCountryCode="true"
          v-model="phone"
          data-vv-validate-on="blur"
          v-validate="'required|min:8|max:16'"
        >
        </vue-tel-input>

        <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>

        <p > Pays <b style="color: #ff6141" >*</b> </p>
        <v-select
          v-validate="'required'"
          name="city"
          :reduce="rep => rep.id"
          class="w-full" label="name"
          v-model="country"
          :options="countries">
        </v-select>

        <span class="text-danger text-sm">{{ errors.first('country') }}</span>
        <p > Ville <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="city"
          v-model="city"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('city') }}</span>

        <p > Mot de passe <b style="color: #ff6141" >*</b> </p>
        <vs-input
          ref="password"
          type="password"
          data-vv-validate-on="blur"
          v-validate="'required|min:8'"
          name="password"
          v-model="password"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('password') }}</span>

        <p > Confirmer le mot de passe <b style="color: #ff6141" >*</b> </p>
        <vs-input
          type="password"
          v-validate="'confirmed:password'"
          data-vv-validate-on="blur"
          data-vv-as="password"
          name="confirm_password"
          v-model="confirm_password"
          class="w-full" />
        <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>
      </div>
    </div>

      <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">J'accepte les termes et conditions.</vs-checkbox>
      <vs-button  type="border" to="/pages/login" class="mt-6">Connexion</vs-button>
      <vs-button class="float-right mt-6" @click="registerUserJWt" :disabled="!validateForm">S'inscrire</vs-button>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

// For custom error message
import { Validator } from 'vee-validate'

const dict = {
  custom: {
    last_name: {
      required: 'Le champ nom est obligatoire'
    },
    first_name: {
      required: 'Le champ prénoms est obligatoire'
    },
    birthDate: {
      required: 'Le champ date de naissance est obligatoire'
    },
    gender: {
      required: 'Le champ sexe est obligatoire'
    },
    city: {
      required: 'Le champ ville est obligatoire'
    },

    country: {
      required: 'Le champ pays est obligatoire'
    },
    phone: {
      required: 'Le champ téléphone est obligatoire'
    },
    displayName: {
      required: 'Le champ nom d\'utilisateur est obligatoire'
    },
    email: {
      required: 'Le champ email est obligatoire'
    },

    password: {
      required: 'Le champ mot de passe est obligatoire',
      min: 'Ce mot de passe est trop court. Il doit contenir au minimum 8 caractères.'
    },

    confirm_password: {
      required: 'Le champ de confirmation de mot de passe est obligatoire',
      confirmed: 'La confirmation du mot de passe ne correspond pas'
    }
  }
}


// register custom messages
Validator.localize('en', dict)

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      // cities: [],
      countries: [],
      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      displayName: '',
      email: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      birthDate: '',
      gender: '',
      city: '',
      country: '',
      phone: '',
      code: '',
      // bindProps: {
      //   mode: "international",
      //   placeholder: "Telephone",
      //   required: true,
      //   enabledFlags: true,
      //   autocomplete: "off",
      //   name: "telephone",
      //   maxLen: 16,
      //   validCharactersOnly:true,
      //   inputOptions: {
      //     showDialCode: true
      //   }
      // },
      isTermsConditionAccepted: true
    }
  },
  watch: {
    // country (value) {
    //   if (value) {
    //     this.$http.get(`countries/${value}/`)
    //       .then((response) => {
    //         this.phone = response.data.countryCode
    //       })
    //       .catch(() => {})
    //   }
    // }
  },

  computed: {
    validateForm () {
      return !this.errors.any() &&
        this.displayName !== ''
        && this.email !== ''
        && this.password !== ''
        && this.city !== '' && this.country !== '' &&
        this.confirm_password !== ''
        && this.isTermsConditionAccepted === true
    }
  },
  methods: {
    countryChanged (country) {
      this.phone = ''
      const countryCode = country.dialCode
      // this.phone = '+'+country.dialCode
      this.$http.post('code-country/', { countryCode })
        .then((response) => {
          this.country = response.data[0].id
          // console.log("voir le pays",response.data[0].name)
        })
        .catch(() => {})


    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    registerUserJWt () {

      if (!this.validateForm || !this.checkLogin()) return
      this.$vs.loading()

      const data = {
        username: this.displayName,
        email: this.email,
        password: this.password,
        password2: this.confirm_password,
        first_name: this.first_name,
        last_name: this.last_name,
        phoneNumber: this.phone,
        birthDate: this.$store.state.convertDateTime(this.birthDate).date,
        gender: this.gender,
        city: this.city,
        defaultCountryCode: '+228',
        country: this.country
      }

      this.$http.post('register/', data)
        .then(() => {
          this.$vs.notify({
            title: 'INSCRIPTION',
            text: 'Vous vous êtes inscrit avec succès.',
            color: 'success',
            position:'top-center'
          })
          // window.getPrendTaCom.success('Vous vous êtes inscrit avec succès.', response)
          this.$router.push('/pages/login').catch(() => {})
          this.$vs.loading.close()
        })
        .catch((error) => {
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]

            if (item === 'username') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Nom d\'utilisateur ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'phoneNumber') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Téléphone vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'email') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'L\'Email déjà utilisé ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'password') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Mot de passe',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'password2') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Confirmer le mot de passe',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'first_name') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Prénoms vide ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'last_name') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Nom vide ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'birthDate') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Date de naissance vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'gender') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Sexe vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'city') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Ville vide',
                color: 'warning',
                position:'top-center'
              })

            }

          }
          this.$vs.notify({
            time:8000,
            title: 'ENREGISTREMENT',
            text: 'L\'enregistrement a échoué',
            color: 'danger',
            position:'top-right'
          })
          this.$vs.loading.close()
        })
    },
    getAllContries () {
      delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    this.getAllContries()
  }
}
</script>
